import React from 'react';
import { TabList } from '@mui/lab';
import { Divider, IconButton, Stack, Tab, tabsClasses, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch, useSelector } from 'redux/store';
import { remove, select } from 'redux/slices/menu';
import { TreeMenuItem } from 'types/common/tree';

type PropTypes = {
    tabs: TreeMenuItem[];
    handleChange: (event: React.SyntheticEvent, link: string) => void;
    handleClose: (event: React.MouseEvent, tab: TreeMenuItem) => void;
};

function DashboardTabs({ tabs, handleChange, handleClose }: PropTypes) {
    return (
        <Stack component='nav'>
            <TabList
                onChange={handleChange}
                variant='scrollable'
                scrollButtons
                allowScrollButtonsMobile
                sx={{
                    [`& .${tabsClasses.scrollButtons}`]: {
                        width: 28,
                        '&.Mui-disabled': { opacity: 0.2 },
                    },
                    backgroundColor: 'grey.200',
                    boxShadow: 'inset 0 -4px 6px rgba(0, 0, 0, 0.02)',
                    height: 40,
                    minHeight: 40,
                }}
            >
                {tabs.map((tab) => (
                    <Tab
                        key={tab.link}
                        value={tab.link}
                        sx={{
                            '&.Mui-selected': {
                                fontWeight: 'bold',
                                backgroundColor: 'white',
                                borderRadius: '8px 8px 0 0',
                                height: 36,
                                minHeight: 36,
                                margin: '6px 0 0 0',
                                boxShadow: '0 -2px 12px rgba(0, 0, 0, 0.12)',
                            },
                            '&:hover': {
                                backgroundColor: 'white',
                                borderRadius: '8px 8px 0 0',
                                height: 36,
                                minHeight: 36,
                                margin: '6px 0 0 0',
                                boxShadow: '0 -2px 12px rgba(0, 0, 0, 0.12)',
                            },
                            '&:not(.Mui-selected)::after': {
                                content: '""',
                                display: 'inline-block',
                                position: 'absolute',
                                bottom: 16,
                                right: 0,
                                backgroundColor: 'grey.300',
                                width: '1px',
                                height: '16px',
                            },
                        }}
                        label={
                            <Stack direction='row' justifyContent='center' alignItems='center'>
                                <Typography color='grey.800' fontSize={12.5} fontWeight={700}>
                                    {tab.title}
                                </Typography>
                                <IconButton
                                    component='div'
                                    size='small'
                                    onClick={(event) => handleClose(event, tab)}
                                    sx={{ color: 'grey.400', p: 0, ml: 0.4 }}
                                >
                                    <CloseIcon fontSize='inherit' />
                                </IconButton>
                            </Stack>
                        }
                    />
                ))}
            </TabList>
        </Stack>
    );
}

export default DashboardTabs;
