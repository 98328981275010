import { alpha } from '@mui/material/styles';

// ----------------------------------------------------------------------

export type ColorSchema = 'primary' | 'secondary' | 'info' | 'success' | 'warning' | 'error';

declare module '@mui/material/styles/createPalette' {
    interface TypeBackground {
        neutral: string;
    }

    interface SimplePaletteColorOptions {
        lighter: string;
        darker: string;
        logo?: string;
    }

    interface PaletteColor {
        lighter: string;
        darker: string;
        logo?: string;
    }
}

// SETUP COLORS

const GREY = {
    0: '#FFFFFF',
    100: '#F9FAFB',
    200: '#F4F6F8',
    300: '#D9DFEA',
    400: '#a8b0be',
    500: '#838b96',
    600: '#6a747a',
    700: '#454F5B',
    800: '#252b36',
    900: '#161C24',
};

const PRIMARY = {
    name: 'purple',
    lighter: '#dabff6',
    light: '#ac76e8',
    main: '#6c1cca',
    dark: '#5519a4',
    darker: '#481c83',
    contrastText: '#FFFFFF',
    logo: '#ffbb2a',
};

const SECONDARY = {
    lighter: '#f5bedb',
    light: '#ee68ab',
    main: '#db247c',
    dark: '#c42375',
    darker: '#871d63',
    contrastText: '#FFFFFF',
};

const INFO = {
    lighter: '#eddcfd',
    light: '#ac76e8',
    main: '#6c1cca',
    dark: '#5519a4',
    darker: '#481c83',
    contrastText: '#FFFFFF',
};

const SUCCESS = {
    lighter: '#F3FCD1',
    light: '#CCF075',
    main: '#108B4A',
    dark: '#59940F',
    darker: '#336205',
    contrastText: '#FFFFFF',
};

const WARNING = {
    lighter: '#FEF1CF',
    light: '#FEC86F',
    main: '#FC8E11',
    dark: '#B55408',
    darker: '#782B03',
    contrastText: '#FFFFFF',
};

const ERROR = {
    lighter: '#ffe9eb',
    light: '#ffa7b2',
    main: '#F93650',
    dark: '#B31B4B',
    darker: '#770A41',
    contrastText: '#FFFFFF',
};

const COMMON = {
    common: { black: '#000000', white: '#FFFFFF' },
    primary: PRIMARY,
    secondary: SECONDARY,
    info: INFO,
    success: SUCCESS,
    warning: WARNING,
    error: ERROR,
    grey: GREY,
    divider: alpha(GREY[500], 0.19),
    action: {
        hover: alpha(GREY[400], 0.12),
        selected: alpha(GREY[500], 0.1),
        disabled: alpha(GREY[500], 0.6),
        disabledBackground: alpha(GREY[500], 0.2),
        focus: alpha(GREY[500], 0.24),
        hoverOpacity: 0.08,
        disabledOpacity: 0.48,
        selectedOpacity: 0.15,
    },
};

const CBT = {
    name: 'purple',
    lighter: '#dabff6',
    light: '#ac76e8',
    main: '#6c1cca',
    dark: '#5519a4',
    darker: '#481c83',
    contrastText: '#FFFFFF',
    logo: '#ffbb2a',
};

const OMS = {
    name: 'orange',
    lighter: '#fce4aa',
    light: '#ffc469',
    main: '#ff9509',
    dark: '#e3770a',
    darker: '#d36b01',
    contrastText: '#FFFFFF',
    logo: '#ffbb2a',
};

const WEVERSE = {
    name: 'mint',
    lighter: '#c1efef',
    light: '#84e1df',
    main: '#04c2c0',
    dark: '#05abaa',
    darker: '#078f8e',
    contrastText: '#FFFFFF',
    logo: '#08ccca',
};

const WCONCEPT = {
    name: 'mint',
    lighter: '#939393',
    light: '#525252',
    main: '#333333',
    dark: '#1f1e1e',
    darker: '#000000',
    contrastText: '#FFFFFF',
    logo: '#FF563F',
};

export function dashboardPalette(colorModel?: 'cbt' | 'oms' | 'weverse') {
    if (colorModel === 'cbt') {
        return CBT;
    }
    if (colorModel === 'oms') {
        return OMS;
    }
    if (colorModel === 'weverse') {
        return WEVERSE;
    }
    if (colorModel === 'wconcept') {
        return WCONCEPT;
    }
    return CBT;
}

export default function palette(themeMode: 'light' | 'dark') {
    const light = {
        ...COMMON,
        mode: 'light',
        text: {
            primary: GREY[900],
            secondary: GREY[600],
            disabled: GREY[500],
        },
        background: { paper: '#FFFFFF', default: '#FFFFFF', neutral: GREY[300] },
        action: {
            ...COMMON.action,
            active: GREY[600],
        },
    } as const;

    const dark = {
        ...COMMON,
        mode: 'dark',
        text: {
            primary: '#FFFFFF',
            secondary: GREY[500],
            disabled: GREY[600],
        },
        background: {
            paper: GREY[800],
            default: GREY[900],
            neutral: alpha(GREY[500], 0.16),
        },
        action: {
            ...COMMON.action,
            active: GREY[500],
        },
    } as const;

    return themeMode === 'light' ? light : dark;
}
