import axios, { AxiosError, AxiosResponse } from 'axios';
import {
    isLoggedIn as isAppLoggedIn,
    setAuthTokens as setAppAuthTokens,
    clearAuthTokens as clearAppAuthTokens,
    getAccessToken as getAppAccessToken,
    getRefreshToken as getAppRefreshToken,
    IAuthTokens,
    TokenRefreshRequest,
    applyAuthTokenInterceptor,
} from 'axios-jwt';
import { clientKey } from '@/settings/config';
import Result from 'types/common/result';
import Token from 'types/common/token';

const BASE_URL = import.meta.env.VITE_BASE_URL;
const ADHOC_URL = import.meta.env.VITE_ADHOC_URL;
const WING_URL = import.meta.env.VITE_WING_URL;
const CENTER_URL = import.meta.env.VITE_CENTER_URL;

const publicInstance = axios.create({ baseURL: BASE_URL });
const privateInstance = axios.create({ baseURL: BASE_URL });
const wingPrivateInstance = axios.create({ baseURL: WING_URL });
const centerPrivateInstance = axios.create({ baseURL: CENTER_URL });
const adhocPublicInstance = axios.create({ baseURL: ADHOC_URL });
const adhocPrivateInstance = axios.create({ baseURL: ADHOC_URL });

const isLoggedIn = isAppLoggedIn;

const getAccessToken = getAppAccessToken;

const getRefreshToken = getAppRefreshToken;

const setAuthTokens = ({ accessToken, refreshToken, client }: IAuthTokens & { client: string }) => {
    setAppAuthTokens({ accessToken, refreshToken });
    localStorage.setItem(clientKey, client);
};

const clearAuthTokens = () => {
    clearAppAuthTokens();
    localStorage.removeItem(clientKey);
};

const publicResponseInterceptor = ({ data }: AxiosResponse) => {
    return data;
};

const privateResponseInterceptor = async (response: AxiosResponse<Result>) => {
    if (response.config.responseType === 'blob') {
        // Blob 응답 처리
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => {
                try {
                    const result = JSON.parse(reader.result as string);
                    if (result.code && result.code !== 2000) {
                        reject(result);
                    } else {
                        resolve(response);
                    }
                } catch (error) {
                    // JSON 파싱 실패 시 Blob 응답을 반환
                    resolve(response);
                }
            };
            reader.onerror = () => {
                reject(reader.error);
            };
            reader.readAsText(response.data as unknown as Blob);
        });
    }
    const { data } = response;
    if (!data.code) {
        return response;
    }
    if (data.code !== 2000) {
        return Promise.reject(data);
    }
    return data.result;
};

const requestRefresh: TokenRefreshRequest = async (refreshToken: string): Promise<IAuthTokens> => {
    const clientJSON = localStorage.getItem('authClient');
    if (!clientJSON) {
        clearAuthTokens();
        return Promise.reject();
    }

    const { client } = JSON.parse(clientJSON);
    const token = await publicInstance.post<Token | Result, Token | Result>('/v1/auth/token', {
        grant_type: 'refresh_token',
        refresh_token: refreshToken,
        client_id: client.id,
        client_secret: client.secret,
    });

    const isFail = (result: any): result is Result => result.code;
    if (isFail(token)) {
        clearAuthTokens();
        return Promise.reject(token);
    }

    return {
        accessToken: token.access_token,
        refreshToken: token.refresh_token,
    };
};

publicInstance.interceptors.response.use(publicResponseInterceptor);
privateInstance.interceptors.response.use(privateResponseInterceptor);
wingPrivateInstance.interceptors.response.use(privateResponseInterceptor);
centerPrivateInstance.interceptors.response.use(privateResponseInterceptor);
adhocPublicInstance.interceptors.response.use(publicResponseInterceptor);
adhocPrivateInstance.interceptors.response.use(privateResponseInterceptor);

applyAuthTokenInterceptor(privateInstance, { requestRefresh });
applyAuthTokenInterceptor(wingPrivateInstance, { requestRefresh });
applyAuthTokenInterceptor(centerPrivateInstance, { requestRefresh });
applyAuthTokenInterceptor(adhocPrivateInstance, { requestRefresh });

export {
    publicInstance,
    privateInstance,
    wingPrivateInstance,
    centerPrivateInstance,
    adhocPublicInstance,
    adhocPrivateInstance,
    isLoggedIn,
    setAuthTokens,
    clearAuthTokens,
    getAccessToken,
    getRefreshToken,
};
