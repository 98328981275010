import React, { ComponentPropsWithRef, ComponentType, lazy, LazyExoticComponent, Suspense } from 'react';
import ProgressScreen from 'components/page/loading/ProgressScreen'; // 잠시 생략하는게 좋을 것으로 판단...

const Loadable = <T extends ComponentType<any>>(Component: LazyExoticComponent<T>) =>
    function LoadableComponent(props: ComponentPropsWithRef<T>) {
        return (
            <Suspense>
                <Component {...props} />
            </Suspense>
        );
    };

// ----------------------------------------------------------------------
// CBT
export const LazyTestPage = Loadable(lazy(() => import('pages/TestPage')));

// ----------------------------------------------------------------------
// [AUTH]

// [AUTH > SIGN IN]
export const LazySignInPage = Loadable(lazy(() => import('pages/auth/SignInPage')));
// [AUTH > SIGN UP]
export const LazySignUpPage = Loadable(lazy(() => import('pages/auth/SignUpPage')));
// [AUTH > FIND PASSWORD]
export const LazyFindPasswordPage = Loadable(lazy(() => import('pages/auth/FindPasswordPage')));
// [AUTH > EMAIL VERIFY]
export const LazyEmailVerifyPage = Loadable(lazy(() => import('pages/auth/EmailVerifyPage')));
// [AUTH > RESET PASSWORD]
export const LazyResetPasswordPage = Loadable(lazy(() => import('pages/auth/ResetPasswordPage')));

// ----------------------------------------------------------------------
// [Dashboard]

export const LazyDashboardPage = Loadable(lazy(() => import('pages/DashboardPage')));
export const LazyDashboardPage1 = Loadable(lazy(() => import('sections/dashboard/index1')));
export const LazyDashboardPage2 = Loadable(lazy(() => import('sections/dashboard/index2')));
export const LazyDashboardPage3 = Loadable(lazy(() => import('sections/dashboard/index3')));
export const LazyDashboardPage4 = Loadable(lazy(() => import('sections/dashboard/index4')));
export const LazyDashboardPage5 = Loadable(lazy(() => import('sections/dashboard/index5')));
export const LazyDashboardPage6 = Loadable(lazy(() => import('sections/dashboard/index6')));
export const LazyDashboardPage7 = Loadable(lazy(() => import('sections/dashboard/index7')));
export const LazyDashboardPage8 = Loadable(lazy(() => import('sections/dashboard/index8')));
export const LazyDashboardPage9 = Loadable(lazy(() => import('sections/dashboard/index9')));
export const LazyDashboardPage10 = Loadable(lazy(() => import('sections/dashboard/index10')));
export const LazyDashboardPage11 = Loadable(lazy(() => import('sections/dashboard/index11')));
export const LazyDashboardPage12 = Loadable(lazy(() => import('sections/dashboard/index12')));
export const LazyDashboardPage13 = Loadable(lazy(() => import('sections/dashboard/index13')));
export const LazyDashboardPage14 = Loadable(lazy(() => import('sections/dashboard/index14')));
export const LazyDashboardPage15 = Loadable(lazy(() => import('sections/dashboard/index15')));
export const LazyDashboardPage16 = Loadable(lazy(() => import('sections/dashboard/index16')));
export const LazyDashboardPage17 = Loadable(lazy(() => import('sections/dashboard/index17')));

// [대시보드 알림 -> 알림리스트]
export const LazyNotificationPage = Loadable(lazy(() => import('pages/dashboard/notification/NotificationPage')));
// [대시보드 검색 상세결과]
export const LazySearchResultPage = Loadable(lazy(() => import('pages/dashboard/search/SearchResultPage')));
// [상세 검색]
export const LazySearchPage = Loadable(lazy(() => import('pages/dashboard/search/SearchPage')));
// [물류비 견적]
export const LazySellerEstimatePage = Loadable(lazy(() => import('pages/dashboard/estimate/SellerEstimatePage')));
export const LazyEstimatePage = Loadable(lazy(() => import('pages/dashboard/estimate/EstimatePage')));
export const LazyEstimateDetailPage = Loadable(lazy(() => import('pages/dashboard/estimate/EstimateDetailPage')));

// [주문 관리]

// [주문 관리 > 주문 접수-OMS]
export const LazyRegisterOrderPage = Loadable(lazy(() => import('@/pages/dashboard/order/RegisterOrderPage')));

// [주문 관리 > 주문 현황]
export const LazyOrderPage = Loadable(lazy(() => import('pages/dashboard/order/OrderPage')));

// [주문 관리 > 주문 현황-OMS]
export const LazyTenantOrderPage = Loadable(lazy(() => import('@/pages/dashboard/order/TenantOrderPage')));

// [주문 관리 > 배송 현황-OMS]
export const LazyDeliveryPage = Loadable(lazy(() => import('pages/dashboard/order/DeliveryPage')));

// [주문 관리 > 배송 조회]
export const LazyTrackingPage = Loadable(lazy(() => import('pages/TrackingPage')));

// [주문 관리 > 출고 처리]
export const LazySellerOutboundPage = Loadable(lazy(() => import('pages/dashboard/order/SellerOutboundPage')));

// ----------------------------------------------------------------------
// [입고 관리]

// [입고 관리 > 입고 현황]
export const LazyInputPage = Loadable(lazy(() => import('pages/dashboard/input/InputPage')));
// [입고 관리 > 운송장 출력]
export const LazyWaybillPage = Loadable(lazy(() => import('pages/dashboard/input/WaybillPage')));
// [입고 관리 > 체적기통과(테스트 페이지입니다)]
export const LazySimulatorPage = Loadable(lazy(() => import('pages/dashboard/input/SimulatorPage')));

// ----------------------------------------------------------------------
// [출고 관리]

// [출고 관리 > 출고 현황]
export const LazyOutputPage = Loadable(lazy(() => import('pages/dashboard/output/OutputPage')));

// [출고 관리 > 출고 처리]
export const LazyLoadingPage = Loadable(lazy(() => import('pages/dashboard/output/LoadingPage')));

// [출고 관리 > 마스터번호 관리]
export const LazyMasterNoPage = Loadable(lazy(() => import('pages/dashboard/output/MasterPage')));

// [출고 관리 > 팔레트 관리]
export const LazyPalletPage = Loadable(lazy(() => import('@/pages/dashboard/output/PalletPage')));

// ----------------------------------------------------------------------
// [통관 관리]

// [통관 관리 > 수출 신고 현황]
export const LazyExportPage = Loadable(lazy(() => import('pages/dashboard/customsClearance/ExportPage')));

export const LazyTenantExportPage = Loadable(lazy(() => import('pages/dashboard/customsClearance/TenantExportPage')));

// [통관 관리 > 통관 영문 변환]
export const LazyTranslationsPage = Loadable(lazy(() => import('pages/dashboard/customsClearance/TranslationPage')));

// ----------------------------------------------------------------------
// [고객지원 서비스]

// [고객지원 서비스 > CS 문의내역]
export const LazyCSPage = Loadable(lazy(() => import('pages/dashboard/cs/CsPage')));

// [고객지원 서비스 > 공지사항]
export const LazyNoticePage = Loadable(lazy(() => import('pages/dashboard/cs/NoticePage')));

export const LazyNoticeDetailPage = Loadable(lazy(() => import('pages/dashboard/cs/NoticeDetailPage')));

export const LazyNoticeCreatorPage = Loadable(lazy(() => import('pages/dashboard/cs/NoticeCreatorPage')));

export const LazyNoticeEditPage = Loadable(lazy(() => import('pages/dashboard/cs/NoticeEditPage')));

// [고객지원 서비스 > CS 문의내역(oms)]
export const LazySellerCSPage = Loadable(lazy(() => import('pages/dashboard/cs/SellerCsPage')));

// [고객지원 서비스 > 공지사항(oms)]
export const LazySellerNoticePage = Loadable(lazy(() => import('pages/dashboard/cs/SellerNoticePage')));

export const LazySellerNoticeDetailPage = Loadable(lazy(() => import('pages/dashboard/cs/SellerNoticeDetailPage')));

// ----------------------------------------------------------------------
// [기준 정보 관리]

// [기준 정보 관리 > 계정 관리]
export const LazyAccountManagePage = Loadable(lazy(() => import('pages/dashboard/information/AccountPage')));

// [기준 정보 관리 > 계정 관리 > 계정 상세]
export const LazyAccountDetailPage = Loadable(lazy(() => import('pages/dashboard/information/AccountDetailPage')));

// [기준 정보 관리 > 셀러 관리]
export const LazyTenantManagePage = Loadable(lazy(() => import('pages/dashboard/information/TenantPage')));

// [기준 정보 관리 > 셀러 관리 > 셀러 상세]
export const LazyTenantDetailPage = Loadable(lazy(() => import('pages/dashboard/information/TenantDetailPage')));

// [기준 정보 관리 > 셀러 관리 > 예치금 상세페이지]
export const LazyTenantDepositDetailPage = Loadable(
    lazy(() => import('@/components/sections/information/tenant/TenantDepositInfo'))
);

// [기준 정보 관리 > 배송 타입 관리]
export const LazyDeliveryTypeManagePage = Loadable(lazy(() => import('pages/dashboard/information/DeliveryTypePage')));

// [기준 정보 관리 > 기준 통화 관리]
export const LazyStandardCurrencyManagePage = Loadable(
    lazy(() => import('pages/dashboard/information/StandardCurrencyPage'))
);

// [기준 정보 관리 > HSCODE 관리]
export const LazyHSCodeManagePage = Loadable(lazy(() => import('pages/dashboard/information/HSCodePage')));

// [기준 정보 관리 > 부피 정보 관리]
export const LazyVolumeManagePage = Loadable(lazy(() => import('pages/dashboard/information/VolumePage')));

// [기준 정보 관리 > 가입 정보 관리]
export const LazySignManagePage = Loadable(lazy(() => import('pages/dashboard/information/SignPage')));

// [기준 정보 관리 > 가입 정보 관리(User)]
export const LazySignUserManagePage = Loadable(lazy(() => import('pages/dashboard/information/SignUserPage')));

// [기준 정보 관리 > 템플릿 다운로드]
export const LazyDownloadTemplatePage = Loadable(
    lazy(() => import('pages/dashboard/information/DownloadTemplatePage'))
);

// [기준 정보 관리 > 운송장 대역 관리]
export const LazyCouriersManagePage = Loadable(lazy(() => import('pages/dashboard/information/CouriersPage')));

// [기준 정보 관리 > 수출 신고 관리(oms)]
export const LazyExportManagePage = Loadable(lazy(() => import('pages/dashboard/customsClearance/ExportManagePage')));

// ----------------------------------------------------------------------

// [정산 관리]

// [정산 관리 > 매입 자료]
export const LazyIncomePage = Loadable(lazy(() => import('pages/dashboard/settlement/IncomePage')));

// [정산 관리 > 매출 자료]
export const LazyOutcomePage = Loadable(lazy(() => import('pages/dashboard/settlement/OutcomePage')));

// [정산 관리 > 예치금 관리]
export const LazyDepoistManagePage = Loadable(lazy(() => import('pages/dashboard/deposit/DepositPage')));

// [정산 관리 > 예치금 관리 > 예치금 상세페이지]
export const LazyDepositDetailPage = Loadable(
    lazy(() => import('@/components/sections/information/deposit/DepositDetailPage'))
);

// [정산 관리 > 예치금 관리(oms)]
export const LazySellerDepositPage = Loadable(lazy(() => import('pages/dashboard/deposit/SellerDepositPage')));
// ----------------------------------------------------------------------
// [이벤트 관리]

// [이벤트 관리 > 이벤트 현황]
export const LazyCampaignPage = Loadable(lazy(() => import('pages/dashboard/campaign/CampaignPage')));

// [이벤트 관리 > 이벤트 현황-OMS]
export const LazyCampaignSellerPage = Loadable(lazy(() => import('pages/dashboard/campaign/CampaignSellerPage')));

// [이벤트 관리 > 사은품 현황]
export const LazyGiftPage = Loadable(lazy(() => import('pages/dashboard/campaign/GiftPage')));
