import React, { PropsWithChildren, useMemo } from 'react';
import { ThemeProvider, useTheme } from '@mui/material';
import { alpha, createTheme } from '@mui/material/styles';
import { useAuthQuery } from 'api/hooks/users/usersQuery';
import { dashboardPalette } from 'theme/options/palette';
import merge from 'lodash/merge';
import useMenusQuery from 'api/hooks/menus/menusQuery';

function DashboardThemeProvider({ children }: PropsWithChildren) {
    const {
        data: { theme },
    } = useMenusQuery({
        placeholderData: () => [],
    });

    const outerTheme = useTheme();

    const presetsColor = dashboardPalette(theme?.name);

    const themeOptions = useMemo(
        () => ({
            palette: {
                primary: presetsColor,
            },
            customShadows: {
                primary: `0 8px 16px 0 ${alpha(presetsColor.main, 0.24)}`,
            },
        }),
        [presetsColor]
    );

    const customTheme = createTheme(merge(outerTheme, themeOptions));

    return <ThemeProvider theme={customTheme}>{children}</ThemeProvider>;
}

export default DashboardThemeProvider;
